import './App.css';
import { Sidebar } from './Sidebar';
import Pusher from 'pusher-js'
import { useEffect, useState } from 'react';
import axios from './axios';
import Chat from './Chat';
import Login from './Login';
import Ads from './Ads';
import { useStateValue } from './StateProvider';
import {
  BrowserRouter as Router,
  Switch,
  Route} from "react-router-dom"

function App() {
  const [messages, setMessages] =  useState([]);
  const [{ user }, dispatch] = useStateValue();
  const [query, setQuery] = useState('');

  useEffect(() => {
    axios.get('/api/v1/messages/sync')
    .then(response => {
      console.log(response.data);
      setMessages(response.data); 
    })
  }, [])
  useEffect(() => {
     //when app component loads run this once
     const pusher = new Pusher('70bc83b4797417ee903f', {
      cluster: 'us2'
    });

    const channel = pusher.subscribe('messages');
    channel.bind('inserted', (data) => {
      //setMessages([...messages, data])
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    }
  }, [messages])

  const handleQueryChange = (newQuery) => {
  axios.post('https://api.textrazor.com/', {
      text: newQuery,
      extractors: 'entities'
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-TextRazor-Key': 'ec699499fb808a62f234d92ed6e42df59968b0ac5dd22edd7b625811'
      }
    })
      .then(response => {
        // Handle the response data here
        console.log(response.data);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
   // setQuery(newQuery);
  };

  return (
    <div className="app">
      {!user? (
        <Login />
      ): (

        <div className="app__body">
          <aside className='aside'>   <Ads dataAdSlot='9210087328' query={query} /></aside>
       
        <Router>
        <Sidebar />
          <Switch>
            <Route path='/rooms/:roomId'>
              <Chat onQueryChange={handleQueryChange} /> 
            </Route>
          </Switch>
        </Router>
        </div>
      )}
    </div>
  );
}

export default App;
