import React from 'react'
import './Ads.css';
import { useEffect, useState } from 'react';
import axios from './axios';

const apiKey = 'AIzaSyAVv5QBhao-K2KLPBTWJ-kOrihksYQsbXg';
const searchQuery = 'baseball';

// Construct the API request URL
const apiUrl = `https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=017576662512468239146:omuauf_lfve&q=${searchQuery}`;

// Make the API request

function Ads(props) {
    let { dataAdSlot, query } = props;
    const [imageUrl, setImageUrl] = useState(null);

    // useEffect(() => {
    //     const fetchImage = async () => {
    //         console.log('fetching image' + query);  
    //         if(!query) {
    //             query = 'baseball'
    //         }
    //       try {
    //         const response = await axios.get(`https://api.unsplash.com/photos/random?query=${query}&client_id=k0bmGPiE-brTndyxXKwzuXjWppYXp49r3WuEm1foUPw`);
    //         setImageUrl(response.data.urls.regular);
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };
    
    //     fetchImage();
    //   }, [query]);

    useEffect(() => {
        const fetchImages = async () => {
          const response = await axios.get(
            `https://www.googleapis.com/customsearch/v1?cx=b69544f56d40d499a&key=AIzaSyAVv5QBhao-K2KLPBTWJ-kOrihksYQsbXg&q=${query}&searchType=image`
          );

          console.log('google query ' + query)
    
          const imageResults = response.data.items;
            console.log(imageResults)
            setImageUrl(imageResults[0].link);
        //   setSearchResults(imageResults);
        };
    
        fetchImages();
      }, [query]);

    return (
        <div className='ads'>
           <img src={imageUrl} alt="Ad" />
        </div>
    );
}

export default Ads