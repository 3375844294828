import { Avatar, IconButton } from '@material-ui/core';
import { SearchOutlined, AttachFile, MoreVert, InsertEmoticonOutlined, MicOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import "./Chat.css";
import axios from './axios';
import { useStateValue } from './StateProvider';
import { useParams } from "react-router-dom";
import db from './firebase';
import firebase from 'firebase';

function Chat(props) {
    const [input, setInput] = useState('');
    const [{ user }, dispatch] = useStateValue();
    const { roomId } = useParams();
    const [roomName, setRoomName] = useState("");
    const [messages, setMessages] = useState([]);

    const handleQueryChange = (query) => {
        props.onQueryChange(query);
      };
    useEffect(() => {
        if(roomId) {
            db.collection('rooms').doc(roomId).onSnapshot(snapshot => {
                console.log(snapshot.data().name)
                setRoomName(snapshot.data().name)
            })
            db.collection('rooms').doc(roomId).collection('messages')
            .orderBy('timestamp','asc').onSnapshot(snapshot => (setMessages(snapshot.docs.map(doc=>doc.data()))))
        }
    }, [roomId])
    const sendMessage = async (e) => {
        e.preventDefault()
        db.collection('rooms').doc(roomId).collection('messages').add({
            message: input,
            name: user.displayName,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })
        handleQueryChange(input)
        setInput("");
    }
    return (
        <div className="chat">
            <div className="chat__header">
            <Avatar src={user?.photoURL}/>
                <div className="chat__headerInfo">
                    <h3>{ roomName }</h3>
                    <p>Last Seen{" "}
                        {new Date(messages[messages.length-1]?.timestamp?.toDate()).toUTCString()}
                    </p>
                </div>
                <div className="chat__headerRight">
                <IconButton>
                    <SearchOutlined/>
                </IconButton>
                <IconButton>
                    <MoreVert/>
                </IconButton>
                <IconButton>
                    <AttachFile/>
                </IconButton>
                </div>
            </div>
            <div className="chat__body">
            {messages?.map(message => (
                <p className={`chat__message ${message.name == user.displayName && "chat__reciever"}`}>
                <span className='chat__name'>{message.name}</span>
                {message.message}
                <span className='chat__timestamp' >
                    {new Date(message?.timestamp?.toDate()).toUTCString()}
                </span>
                </p>
            ))}      
            </div>
            <div className="chat__footer">
            <IconButton>
               <InsertEmoticonOutlined/>
            </IconButton>
            <form>
                <input value={input} onChange={e => setInput(e.target.value)} placeholder="Type here"></input>
                <button onClick={sendMessage} type="submit"> Send a Message</button>
            </form>
            <IconButton>
               <MicOutlined/>
            </IconButton>
            </div>
        </div>
    )
}

export default Chat