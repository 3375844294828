import { Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import db from './firebase';
import "./SidebarChat.css";
import { Link } from 'react-router-dom';
import { useStateValue } from './StateProvider';

function SidebarChat({id, name, addNewChat}) {
    const [{ user }, dispatch] = useStateValue();
    const [messages, setMessages] = useState("")
    const createNewChat = () => {
        const roomName = prompt("Please enter name for chat");

        if(roomName) {
            db.collection('rooms').add({
                name: roomName
            })
        }
    }
    useEffect(() => {
        if(id) {
            db.collection('rooms').doc(id).collection('messages').orderBy('timestamp','desc').onSnapshot(snapshot => {
                setMessages(snapshot.docs.map((doc)=> doc.data()))
            })
        }
    }, [id]);
    return !addNewChat ? (
        <Link to={`/rooms/${id}`}>
        <div className="sidebarChat">
        <Avatar />
            <div className="sidebarChat__info">
                <h2> {name}</h2>
                <p> {messages[0]?.message}</p>
            </div>
        </div>
        </Link>
    ) : (
        <div onClick={createNewChat}
        className="sidebarChat">
            <h2>Add new Chat</h2>
        </div>
    );
}

export default SidebarChat
